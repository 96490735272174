// @mixin List

// min-width
$breakpoint-up: (
  'xs': 'screen and (min-width: 321px)',
  'sm': 'screen and (min-width: 641px)',
  'md': 'screen and (min-width: 769px)',
  'lg': 'screen and (min-width: 961px)',
  'xl': 'screen and (min-width: 1200px)',
  ) !default;
// max-width
$breakpoint-down: (
  'xs': 'screen and (max-width: 320px)',
  'sm': 'screen and (max-width: 640px)',
  'md': 'screen and (max-width: 768px)',
  // sp 
  'lg': 'screen and (max-width: 960px)',
  // pc
  'xl': 'screen and (max-width: 1199px)',
  ) !default;

// min width ------------------------------------------ 

@mixin mq-u($breakpoint: xs) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-u($breakpoint: sm) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-u($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-u($breakpoint: lg) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-u($breakpoint: xl) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

// max width ------------------------------------------ 

@mixin mq-d($breakpoint: xs) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-d($breakpoint: sm) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-d($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-d($breakpoint: lg) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-d($breakpoint: xl) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}


@mixin fontPrefix($family) {
  font-family: $family;
}

@mixin widtnPercent($parentPxWidth, $pxWidth ) {
  width: floor((($pxWidth / $parentPxWidth ) * 10000%)) / 100;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size
  );
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// border radius

@mixin border-radius($radius: .25em) {
  border-radius: $radius;
}


@mixin stripeBg($type: 45, $color1: #fff, $color2: #000, $size: 10) {
  @if not(unitless($size)) {
    //サイズに単位が付いてたら削除
    $_nounit_divisor: (floor($size) * 2) % 2+1;
    $size: ($size / $_nounit_divisor);
  }

  @if type_of($type)==string {
    //サークル（円）指定の場合
    background: repeating-radial-gradient($type,
        $color1,
        $color1 $size + px,
        $color2 $size + px,
        $color2 (
        $size * 2) + px
    );
}

@else if (type_of($type)==number) {

  //角度指定の場合
  @if not(unitless($type)) {
    //角度に単位が付いてたら削除
    $_nounit_divisor: (floor($type) * 2) % 2+1;
    $type: ($type / $_nounit_divisor);
  }

  background: repeating-linear-gradient($type + deg,
    $color1,
    $color1 $size + px,
    $color2 $size + px,
    $color2 ($size * 2) + px);
}

@else if (type_of($type)==list) {
  //to right,to bottomの場合
  background: repeating-linear-gradient($type,
      $color1,
      $color1 $size + px,
      $color2 $size + px,
      $color2 (
      $size * 2) + px
  );
}
}