// underlayer.scss

.wp-pagenavi {
    clear: both;
    display: flex;
    justify-content: center;
}

.wp-pagenavi a,
.wp-pagenavi span {
    text-decoration: none;
    border: 1px solid #0f6327;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 2px;
    color: #5f6260;
    height: 2rem;
    min-width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.current {
        background: #0f6327;
        color: #fff;
    }
}

.wp-pagenavi span.current {
    font-weight: bold;
}

.br-sp {
    display: inline;
}

.br-pc {
    display: none;
}

.show-sp {
    display: inline;
}

.show-pc {
    display: none;
}

.map {
    display: block;
    width: 100%;
    height: 24rem;
    margin-top: 1rem;
}

@include mq-u(md) {

    .br-sp {
        display: none;
    }

    .br-pc {
        display: inline;
    }

    .show-sp {
        display: none;
    }

    .show-pc {
        display: inline;
    }

    .map {}

}

@media print {}


@include mq-u(sm) {}

@include mq-d(md) {}