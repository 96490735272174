// list.scss
.c-list {
	&__gnav {
		display: none;
	}

	&__footerNav {
		display: none;
	}

	&__worries {
		padding: 0;

		&Item {
			display: block;
			padding: .8rem 1rem;
			background: #fff;
			box-shadow: 0 5px 0 #BE5B75;
			margin: 1.5rem 1.5rem;
			font-weight: 700;
			border-radius: 2rem;
			font-size: 1rem;

			&--man {
				margin-top: 1rem;

				img {
					width: 10rem;
				}
			}
		}
	}

	&__Aitest {
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		&Item {
			margin: .5rem;
			width: 6.5rem;
			height: 6.5rem;
			line-height: 1.2;

			&.plus {
				font-size: .8rem;
				color: #5D90B7;
				border: 1px solid #9FCCEE;

				.plusadd {
					display: block;
					margin: 1.2rem auto .6rem;
					width: 2.5rem;
					height: 2.5rem;
					position: relative;

					&::before {
						content: '';
						display: inline-block;
						height: 2.3rem;
						width: 2px;
						position: absolute;
						background: #9FCCEE;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
					}

					&::after {
						content: '';
						display: inline-block;
						width: 2.3rem;
						height: 2px;
						position: absolute;
						background: #9FCCEE;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
					}
				}
			}
		}
	}

	&__point {
		padding: 0;
		position: relative;
		background: #fff;

		&Name {
			width: 12rem;
			height: 12rem;
			padding: 2rem;
			font-size: 2rem;
			font-weight: 700;
			position: absolute;
			line-height: 1.2;
			right: 0;
			top: -11.8rem;

			small {
				font-size: 1rem;
				display: block;
				font-weight: normal;
				margin-bottom: .5rem;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 4rem;
				height: 4.5rem;
				left: 0;
				top: 0;
				border-top: 2px solid #5F7CAE;
				border-left: 2px solid #5F7CAE;
			}

			&::after {
				content: '';
				background: #DF0039;
				display: block;
				margin: .5rem auto;
				width: 3rem;
				height: 3px;
			}
		}

		&Item {
			display: flex;
			align-items: center;
			border: 2px solid #5F7CAE;
			padding: 1rem;
			margin: 1rem 0;
		}

		&Num {
			display: block;
			color: #5F7CAE;
			flex-shrink: 0;
			width: 3rem;
			font-weight: 600;
			font-size: 1.5rem;
		}

		&Icon {
			display: block;
			width: 4rem;
			flex-shrink: 0;
			margin: 0 1rem;
		}

		&Txt {
			width: calc(100% - 10rem);
			flex-shrink: 0;
			text-align: center;

			strong {
				font-weight: 800;
				font-size: 1.2rem;
			}

			&--red {
				font-size: 1.1rem;
				display: inline;
				border-bottom: 1px solid #DF0039;
				color: #DF0039;
				font-weight: 500; // 梅田が追加
			}
		}
	}

	&__obs3 {
		padding: 0;

		&Item {
			padding: 1.2rem;
			color: #fff;
			border-radius: 30px;
			margin: 1rem auto;
			text-align: left;

			&__Tit {
				font-size: 2.4rem;
				font-weight: 700;
			}

			&:nth-child(1) {
				background: #196096;
			}

			&:nth-child(2) {
				background: #F6A901;
			}

			&:nth-child(3) {
				background: #D73425;
			}
		}
	}

	&__contact {
		max-width: 50rem;
		padding: 0;
		margin: 1rem auto;

		&Item {
			margin-top: 1rem;
		}

		&Name {
			width: 100%;
			font-weight: 700;
			text-align: left;
			position: relative;

			&.must {
				&::after {
					content: '必須';
					background: #DF0039;
					color: #fff;
					display: inline-block;
					padding: .1rem .5rem .1rem;
					font-size: .8rem;
					border-radius: 3px;
					position: absolute;
					right: 0;
				}
			}
		}
	}


}

@include mq-u(md) {
	.c-list {
		&__gnav {
			display: flex;
			width: 100%;
			justify-content: end;

			&Item {
				height: 4.5rem;
				display: flex;
				align-items: center;
			}

			&Link {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000;
				font-weight: 700;
				width: 10rem;
				height: 100%;
				font-size: 1.2rem;

				&:hover {
					color: #fff;
					background: #468fd6;
				}
			}

			&Btn {
				&--tel {
					width: 12rem;
					font-weight: 700;
					padding: 0 1rem;
					font-size: 1.4rem;
					background: #fff;

					small {
						display: block;
						font-weight: normal;
						font-size: 1.1rem;
					}
				}

				&--contact {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 10rem;
					height: 100%;
					color: #fff;
					font-size: 1.2rem;
					font-weight: 700;
					background: #DF0039;

					&:hover {
						background: #96092c;
						color: #fff; // 梅田が追加
					}
				}
			}
		}

		&__footerNav {
			justify-content: center;
			padding: 1rem 0 0;
			margin: auto;
			display: flex;

			&Item {
				margin: .5rem .8rem;

				a {
					color: #fff;

					&:hover {
						opacity: .6;
					}
				}
			}
		}

		&__worries {
			position: relative;
			height: 22rem;

			&Item {
				display: block;
				width: 25rem;
				position: absolute;
				font-size: 1.2rem;

				&:nth-child(1) {
					left: 5rem;
				}

				&:nth-child(2) {
					right: 5rem;
				}

				&:nth-child(3) {
					left: 3rem;
					top: 5rem;
				}

				&:nth-child(4) {
					right: 3rem;
					top: 5rem;
				}

				&:nth-child(5) {
					left: 1rem;
					top: 10rem;

				}

				&:nth-child(6) {
					right: 1rem;
					top: 10rem;
				}

				&--man {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);

					img {
						width: 10rem;
					}
				}
			}
		}

		&__Aitest {
			padding: 0;
			margin: 1.5rem auto;
			display: flex;

			&Item {
				width: 7rem;
				height: 7rem;
			}
		}

		&__point {
			padding: 0;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			width: 45rem;

			&Name {
				width: 14rem;
				height: 16.5rem;
				position: relative;
				right: auto;
				margin: .5rem;
				top: 0;
				font-size: 2.4rem;

				small {
					font-size: 1rem;
					display: block;
					font-weight: normal;
					margin-bottom: .5rem;
					margin-top: 25%;
				}

				&::after {
					content: '';
					background: #DF0039;
					display: block;
					margin: .5rem auto;
					width: 3rem;
					height: 3px;
				}

				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 4rem;
					height: 4.5rem;
					left: 0;
					top: 0;
					border-top: 2px solid #5F7CAE;
					border-left: 2px solid #5F7CAE;
				}
			}

			&Item {
				width: 14rem;
				height: 16.5rem;
				display: flex;
				flex-wrap: wrap;
				padding: 1rem .5rem;
				margin: .5rem;
			}

			&Num {
				width: 100%;
				display: block;
				font-weight: 600;
				font-size: 2rem;
			}

			&Icon {
				display: flex;
				align-items: center;
				width: auto;
				height: 3.5rem;
				flex-shrink: 0;
				margin: 1rem auto 1rem;

				img {
					height: 4rem;
					flex-shrink: 0;
				}
			}

			&Txt {
				width: 100%;
				flex-shrink: 0;
				height: 6rem;
				line-height: 1.4;
				text-align: center;

				strong {
					font-weight: 800;
					font-size: 1.25rem;
				}

				&--red {
					font-size: 1.1rem;

				}
			}
		}

		&__obs3 {
			position: relative;
			display: block;
			height: 65rem;

			&::after {
				content: '';
				display: block;
				width: 60rem;
				height: 65rem;
				background: url(../../img/meterimg.png);
				background-size: cover;
				position: absolute;
				z-index: 1;
				top: -2rem;
				left: 50%;
				transform: translateX(-50%);
			}

			&Item {
				position: absolute;
				z-index: 10;

				&__Tit {
					font-size: 3.4rem;
					font-weight: 700;
					white-space: nowrap;
				}

				&:nth-child(1) {
					left: 8rem;
					top: 12rem;
					width: 16rem;
					background: none;

					.c-list__obs3Item__Tit {
						transform: translateX(2rem);
					}
				}

				&:nth-child(2) {
					right: 8rem;
					top: 12rem;
					width: 16rem;
					background: none;

					.c-list__obs3Item__Tit {
						transform: translateX(-1.5rem);
					}
				}

				&:nth-child(3) {
					.c-list__obs3Item__Tit {
						transform: translateX(-1.7rem);
					}

					width: 26em;
					bottom:9rem;
					left:50%;
					transform: translateX(-50%);

					background: none;
				}
			}
		}

		&__contact {
			max-width: 50rem;
			padding: 0;
			margin: 2rem auto;

			&Item {
				display: flex;
				margin-top: 0;
			}

			&Name {
				width: 16rem;
				margin: 1.2rem .8rem 0 0;
				font-size: 1.1rem;

				&.must {
					&::after {}
				}
			}
		}
	}
}