/* text atyle
=================================================================== */

.u-txt {
    &-l {
        text-align: left;
    }

    &-r {
        text-align: right;
    }

    &-c {
        text-align: center;
    }

    &-l--sp {
        text-align: left;
    }

    &-r--sp {
        text-align: right;
    }

    &-c--sp {
        text-align: center;
    }

    &-c--pc {
        text-align: left;
    }

    &__link {
        color: $clrAnchor;
        text-decoration: underline;

        &:visited {
            color: $clrAnchor;
        }

        &:hover {
            color: $clrAnchorHover;
            text-decoration: none;
        }

        &--haken {
            margin: 0;
            padding: 0;
        }

    }

    &__anchor {
        color: $clrAnchor;
        text-decoration: underline;

        &:visited {
            color: $clrAnchorVisited;
        }

        &:hover {
            color: $clrAnchorHover;
            text-decoration: none;
        }

        &--haken {
            margin: 0;
            padding: 0;
        }
    }

    /* 折り返しポイント */
    &-flap {
        display: block;

        /* SP時文字を折り返す */
        &--sp {
            display: block;
        }
    }

    /* 米印 */
    &__asterisk {
        display: inline-block;
        margin: 0 0 0 1rem;
        text-indent: -1.2em;

        &::before {
            content: "※";
            margin-right: .2em;
        }
    }

    /* 中点 */
    &__middlepoint {
        display: block;
        margin: 0 0 0 1.25rem;
        text-indent: -1.25rem;

        &::before {
            content: "・";
        }
    }

    /* "1)" */
    &__numList {
        &--patternA {
            &::before {
                counter-increment: numListPatternA;
                content: (numListPatternA)")";
            }
        }

        &--circleNum {
            li {
                text-align: left;
                text-indent: -1.5em;
                margin-left: .5em;

                &::before {
                    content: '\2460';
                    margin-right: .4em;
                }
            }

            li+li {
                &::before {
                    content: '\2461'
                }
            }

            li+li+li {
                &::before {
                    content: '\2462'
                }
            }

            li+li+li+li {
                &::before {
                    content: '\2463'
                }
            }

            li+li+li+li+li {
                &::before {
                    content: '\2464'
                }
            }

            li+li+li+li+li+li {
                &::before {
                    content: '\2465'
                }
            }

            li+li+li+li+li+li+li {
                &::before {
                    content: '\2466'
                }
            }

            li+li+li+li+li+li+li+li {
                &::before {
                    content: '\2467'
                }
            }

            li+li+li+li+li+li+li+li+li {
                &::before {
                    content: '\2468'
                }
            }

            li+li+li+li+li+li+li+li+li+li {
                &::before {
                    content: '\2469'
                }
            }

            li+li+li+li+li+li+li+li+li+li+li {
                &::before {
                    content: '\2470'
                }
            }
        }
    }

    /* ボタン等に付ける矢印装飾 */
    &__arrow {
        position: relative;

        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 1rem;
            bottom: 0;
            border-right: 1px solid;
            border-top: 1px solid;
            height: 7px;
            width: 7px;
            margin: auto;
            transform: rotate(45deg);
        }

        &Down {
            &::after {
                position: absolute;
                content: "";
                top: 0;
                right: 1rem;
                bottom: 0;
                border-right: 1px solid;
                border-top: 1px solid;
                height: 7px;
                width: 7px;
                margin: auto;
                transform: rotate(135deg);
            }
        }

        &Up {
            &::after {
                position: absolute;
                content: "";
                top: 0;
                right: 1rem;
                bottom: 0;
                border-right: 1px solid;
                border-top: 1px solid;
                height: 7px;
                width: 7px;
                margin: auto;
                transform: rotate(-45deg);
            }
        }

        &--pattern {
            &A {
                &::after {
                    border-right-color: $clrBase01;
                    border-top-color: $clrBase01;
                }

                &:hover {
                    &::after {
                        border-right-color: $clrWhite;
                        border-top-color: $clrWhite;
                    }
                }
            }

            &B {
                &::after {
                    border-right-color: $clrWhite;
                    border-top-color: $clrWhite;
                }

                &:hover {
                    &::after {
                        border-right-color: $clrBase01;
                        border-top-color: $clrBase01;
                    }
                }
            }

            // フォントによる形成
            &C {
                position: relative;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    content: "＞";
                }
            }
        }

        &--size {
            &Menu {
                &::after {
                    height: 1rem;
                    width: 1rem;
                }
            }
        }

        // 矢印と文字の距離を縮めた配置
        &--range {
            &-short {
                &::after {
                    right: 4rem;
                }
            }
        }
    }

    /* ボタン等に付けるプラス記号装飾 */
    &__plus {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: .6rem;
            bottom: 0;
            height: .2rem;
            width: 1.6rem;
            margin: auto;
            background: $clrBase01;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 1.3rem;
            bottom: 0;
            width: .2rem;
            height: 1.6rem;
            margin: auto;
            transform: rotate(180deg);
            background: $clrBase01;
        }
    }

    /* ボタン等に付けるマイナス記号装飾 */
    &__minus {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: .6rem;
            bottom: 0;
            height: .2rem;
            width: 1.6rem;
            margin: auto;
            background: $clrBase01;
        }
    }

    &__cross {
        position: relative;

        &::before {
            background: $clrWhite;
            bottom: 0;
            content: "";
            height: 2px;
            margin: auto;
            position: absolute;
            right: -22px;
            top: 1px;
            transform: rotate(-35deg);
            width: 20px;
        }

        &::after {
            background: $clrWhite;
            bottom: 0;
            content: "";
            height: 20px;
            margin: auto;
            position: absolute;
            right: -13px;
            top: 2px;
            transform: rotate(-55deg);
            width: 2px;
        }
    }

    &__middlepoint {
        &--before {
            position: relative;
        }

        &--sz-middle {
            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                content: "";
                width: .5rem;
                height: .5rem;
                margin: auto;
                border: 1px solid $clrBase01;
                border-radius: 50%;
                background: $clrBase01;
            }
        }
    }

    /* font-color */
    &__clr {
        &--white {
            color: $clrWhite;
        }

        &--base {
            color: $clrGray05;
        }

        &--red {
            &01 {
                color: $clrRed02;
            }
        }

        &--orgRed {
            color: #ec6941;
        }

        &--org {
            color: #fa8825;
        }

        &--blue {
            color: #2145a8;
        }

        &--green {
            color: #128b1b;
        }

        &--gray {
            color: #525764;
        }
    }

    &__fw {
        &--bold {
            font-weight: bold;
        }

        &--normal {
            font-weight: normal;
        }

        &300 {
            font-weight: 300;
        }

        &500 {
            font-weight: 500;
        }

        &700 {
            font-weight: 700;
        }
    }

    &__fontEg {
        font-family: 'Poppins', sans-serif;
    }

    &__size {
        &--S {
            font-size: .9rem;
        }

        &--M {
            font-size: 1.2rem;
        }

        &--L {
            font-size: 1.4rem;
        }

        &--LL {
            font-size: 1.6rem;
        }

        &--XL {
            font-size: 1.8rem;
        }
    }

    &__indent--1 {
        text-indent: 1rem;
    }
}

@include mq-u(md) {
    .u-txt {

        /* 折り返しポイント */
        &-flap {

            /* PC時文字を折り返さない */
            &--sp {
                display: inline;
            }
        }

        &-l--pc {
            text-align: left;
        }

        &-r--pc {
            text-align: right;
        }

        &-c--pc {
            text-align: center;
        }

        &-l--sp {
            text-align: inherit;
        }

        &-r--sp {
            text-align: inherit;
        }

        &-c--sp {
            text-align: inherit;
        }
    }
}