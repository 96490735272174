/* position style
=================================================================== */
.u-pos {
  &__anchor {
    display: block;
    position: absolute;
    top: -3rem;
  }
}



@include mq-u(md) {
  .u-pos {
    &__anchor {
      top: -4.5rem
    }
  }
}