.ai {

    .imageDataArea {
        margin: 1rem 0;

        .imageDropZoneArea {
            width: 300px;
            margin: 0 auto;

            .imageData {
                height: 250px;
                width: 250px;
                object-fit: contain;
                margin: 5px 0;
            }
        }
    }

    .aiResultArea {
        margin: 1rem 0;

        .aiLoadingArea {
            height: 220px;

            .aiLoading {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

            }
        }

        .aiResultNull {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            font-size: 14px;

            .aiResultNullText {
                font-weight: bold;
                font-size: 16px;
            }

            .aiResultNullMeter {
                margin: 5px 0;

                .aiResultNullMeterTitle {
                    font-size: 16px;
                }
            }

            .aiResultNullUpdate {
                color: red;
                font-weight: bold;
                font-size: 16px;
            }
        }

        .aiResultData {
            height: 220px;
            font-size: 14px;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            .aiResultDataTitleCol {
                line-height: 35px;
                margin: 2px 0;

                .aiResultDataTitle {
                    width: 120px;
                    background: rgba(0, 58, 115, 0.839);
                    color: white;
                    border-radius: 30px;
                    margin: 0 auto;
                }
            }

            .aiResultDataValueCol {
                line-height: 35px;
                margin: 2px 0;
                text-align: center;
            }
        }
    }
}

.aiUseWarningTitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    .aiUseWarningTitleIcon {
        color: #1890ff;
        font-size: 20px;
        margin: 0 5px;
    }
}

.aiUseWarningContent {
    text-align: center;
    font-size: 13px;
    margin: 20px 0;

    .aiUseWarningCheckBox {
        text-align: center;
        margin-top: 20px;
        font-size: 13px;
    }
}

.errorCertificationTitle {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;

    .errorCertificationTitleIcon {
        color: #ff4d4f;
        font-size: 22px;
        margin: 0 5px;
    }
}

.errorCertificationContent {
    font-size: 15px;
    text-align: center;
}

.smartSecurityModal {
    .loadingArea {
        position: relative;
        height: 250px;
        width: 250px;
        margin: 0 auto;

        .loading {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .captchaArea {
        position: relative;
        height: 250px;
        width: 250px;
        margin: 0 auto;

        .meterImage {
            position: absolute;
            height: 250px;
            width: 250px;
        }

        .needleImage {
            position: absolute;
            height: 250px;
            width: 250px;
        }

        .captchaImage {
            position: absolute;
            height: 250px;
            width: 250px;
        }
    }

    .sliderArea {
        width: 280px;
        margin: 0 auto;
    }

    .disciplineText {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        margin-top: 1.5rem;
    }

    .expiryText {
        text-align: center;
        margin-top: 10px;
        font-size: 13px;
    }
}

@include mq-u(md) {
    .ai {

        .imageDataArea {
            .imageDropZoneArea {
                width: 360px;
                margin: 0 auto;

                .imageData {
                    height: 300px;
                    width: 300px;
                    object-fit: contain;
                }
            }
        }

        .aiResultArea {
            .aiLoadingArea {
                height: 220px;

                .aiLoading {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .aiResultNull {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                font-size: 14px;

                .aiResultNullText {
                    font-weight: bold;
                    font-size: 18px;
                }

                .aiResultNullMeter {
                    margin: 5px 0;

                    .aiResultNullMeterTitle {
                        font-size: 16px;
                    }
                }

                .aiResultNullUpdate {
                    color: red;
                    font-weight: bold;
                    font-size: 17px;
                }
            }

            .aiResultData {
                height: 220px;
                font-size: 18px;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;

                .aiResultDataTitleCol {
                    line-height: 35px;
                    margin: 2px 0;

                    .aiResultDataTitle {
                        width: 140px;
                        background: rgba(0, 58, 115, 0.839);
                        color: white;
                        border-radius: 30px;
                        margin: 0 auto;
                    }
                }

                .aiResultDataValueCol {
                    line-height: 35px;
                    margin: 2px 0;
                    text-align: left;
                }
            }
        }
    }

    .smartSecurityModal {
        .loadingArea {
            position: relative;
            height: 400px;
            width: 400px;
            margin: 0 auto;

            .loading {
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .captchaArea {
            position: relative;
            height: 400px;
            width: 400px;
            margin: 0 auto;

            .meterImage {
                position: absolute;
                height: 400px;
                width: 400px;
            }

            .needleImage {
                position: absolute;
                height: 400px;
                width: 400px;
            }

            .captchaImage {
                position: absolute;
                height: 400px;
                width: 400px;
            }
        }

        .sliderArea {
            width: 400px;
            margin: 0 auto;
        }

        .disciplineText {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 1rem;
        }

        .expiryText {
            text-align: center;
            margin-top: 10px;
            font-size: 15px;
        }
    }

    .aiUseWarningTitle {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }

    .aiUseWarningContent {
        text-align: center;
        font-size: 16px;
        margin-top: 30px 0;

        .aiUseWarningCheckBox {
            text-align: center;
            margin-top: 40px;
        }
    }
}