// Variables Scss List

// Prefix Sass
$prefixList: -webkit-, -moz-, -ms-, -o-, null;

// メインカラー
$clrBase01: #000000;
$clrBase01-hover: #007087;

// メインフォントカラー
$clrBase02: #0C0D11;

$clrBase03: #c29e31;

// サブカラー01
$clrSub01: #00b0f1;

// サブカラー02
$clrSub02: #ff5858;

// 全体メイン背景色
$clrBody: #fff;
$clrHeaderBg: rgba(43, 77, 103, .46);
$clrFirstBg: #5bb5e5;

// メインコンテンツボックス影
$clrContentBodyShadow: #dcdad8;

// リンクカラー a
$clrAnchor: #447717;
$clrAnchorHover: #5fb613;
$clrAnchorVisited: #366909;
$clrPageTitle: #a9f8ff;
$clrLightSkyBlue: #49c6e5;

$clrGreenBlue: #5dbbca;
$clrGreenBlueDark: #2e7783;

// Topicsアイコンカラー
$clrIcon-Company: #6757aa;
$clrIcon-pressrelease: #0f5091;
$clrIcon-recruit: #d375b1;
$clrIcon-news: #4c8980;


//フッター関係色
$clrfooterBg01: linear-gradient(#485a6b, #4e6171);
$clrfooterBg02: #656c7f;
$clrfooterBg03: #4a505e;
$clrfooterBg04: #3a3e49;
$clrfooterBg05: rgba(58, 62, 73, .4);
$clrfootertxt: #bcc4d0;

$hslColor: hsl(1, 50%, 50%);
$hslaColor: hsla(120, 50%, 50%, .3);

$clrBlack: #000000;
$clrWhite: #ffffff;
$clrBlue: #2e3184;
$clrNavy: #0f305b;
$clrDarkBlue: #2e3192;
$clrGrayBlue: #67778f;
$clrRed01: #db174b;
$clrRed02: #e7141a;
$clrRed03: #c41e4e;
$clrGray01: #111;
$clrGray02: #222;
$clrGray03: #333;
$clrGray04: #444;
$clrGray05: #555;
$clrGray06: #666;
$clrGray07: #777;
$clrGray08: #888;
$clrGray09: #999;
$clrGray10: #aaa;
$clrGray11: #bbb;
$clrGray12: #ccc;
$clrGray13: #ddd;
$clrGray14: #eee;
$clrGray15: #efefef;
$clrGray16: #fbfbfb;
//
$clrBlueico: #4d89cb;
$clrBlack-8: rgba(0, 0, 0, .8);
$clrBlack-1: rgba(0, 0, 0, .1);

$clrWhite-8: rgba(255, 255, 255, .8);
$clrBlack-n: rgba(0, 0, 0, 1);
$clrPink-n: rgba(255, 42, 97, 1);
$clrwhiteBlue: rgb(217, 233, 242);
$clrskyBlue: rgba(0, 155, 229, 1);
$clrlightBlue: #2880bd;
$clrOrange: rgba(255, 120, 2, 1);
$clrOrange-8: rgba(255, 120, 2, .8);
$clrGreen: rgb(49, 195, 55);
$clrGreen-y: rgb(123, 203, 44);
$clrGreen-b: rgb(43, 206, 156);
$clrYellow: #ffce00;
$clrRed-n: rgba(255, 0, 0, 1);
//

$clrBlack-8: rgba(0, 0, 0, .8);

$clrWhite-n: rgba(255, 255, 255, 1);
$clrWhite-8: rgba(255, 255, 255, .8);
$clrWhite-9: rgba(255, 255, 255, .9);
$clrBlack-n: rgba(0, 0, 0, 1);
$clrPink-n: rgba(255, 42, 97, 1);
$clrBlue-l: rgba(230, 247, 255, 1);
$clrBlue-n: rgba(0, 155, 229, 1);
$clrOrange-n: rgba(255, 120, 2, 1);
$clrRed-n: rgba(255, 0, 0, 1);
$clrLogoGreen: #159e59;
$clrWhiteGreen: #e4f4f2;

$fontBase--size: 1.4rem;

$svgPath: "/common/svg/";
$imagePath: "/common/img/";

$pathSvg: "/common/svg/";
$pathImg: "/common/img/";

%ctrImage {
  width: 100%;
  height: auto;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes bounceInDown {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInUp {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}