.p-floor {
	max-width: 44rem;
	position: relative;
	margin: auto;
	$num: 1;

	@for $i from 0 through 26 {
		[class$="#{$i * $num}"] {
			&::before {
				content: '#{$i * $num}'
			}
		}
	}

	&Img {}

	&__Link {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: absolute;
		background: rgba(19, 124, 67, 0.7);
		width: 1.48rem;
		height: 1.48rem;
		font-size: .8rem;
		border-radius: 100%;
		color: #fff;
		font-weight: 500;
		border: 1px solid #fff;
		box-shadow: 0 0 5px #000;
	}

	&--3f {
		.c01 {
			right: 32%;
			bottom: 14%;
		}

		.c02 {
			right: 26%;
			bottom: 24%;
		}

		.c03 {
			right: 26%;
			bottom: 37%;
		}

		.c04 {
			right: 34%;
			bottom: 38%;
		}

		.c05 {
			right: 46%;
			top: 17%;
		}

		.c06 {
			right: 41%;
			top: 17%;
		}

		.c07 {
			right: 36%;
			top: 17%;
		}

		.c08 {
			right: 31%;
			top: 17%;
		}

		.c09 {
			right: 26%;
			top: 17%;
		}

		.c10 {
			right: 47.5%;
			top: 52%;
		}

		.c11 {
			right: 60.5%;
			top: 52%;
		}

		.c12 {
			right: 66%;
			top: 52%;
		}

		.c13 {
			left: 27.5%;
			top: 28%;
		}

		.c14 {
			right: 58.5%;
			top: 20%;
		}

		.c15 {
			right: 46%;
			bottom: 17%;
		}

		.c16 {
			left: 43%;
			bottom: 17%;
		}

		.c17 {
			left: 38%;
			bottom: 17%;
		}

		.c18 {
			left: 33%;
			bottom: 17%;
		}

		.c19 {
			left: 26%;
			bottom: 17%;
		}

		.c20 {
			left: 19%;
			bottom: 26%;
		}

		.c21 {
			left: 14%;
			bottom: 33%;
		}

		.c22 {
			left: 9%;
			bottom: 33%;
		}

		.c23 {
			left: 10%;
			bottom: 21%;
		}

		.c24 {
			left: 3%;
			bottom: 27%;
		}

		.c25 {
			left: 5%;
			top: 40%;
		}

		.c26 {
			left: 3%;
			top: 17%;
		}
	}

	&--5f {
		.c01 {
			right: 27.5%;
			bottom: 40%;
		}

		.c02 {
			right: 34%;
			bottom: 32%;
		}

		.c03 {
			right: 26%;
			bottom: 20%;
		}

		.c04 {
			right: 36%;
			top: 18%;
		}

		.c05 {
			right: 38%;
			top: 43%;
		}

		.c06 {
			right: 42%;
			top: 43%;
		}

		.c07 {
			right: 46%;
			top: 43%;
		}

		.c08 {
			right: 50%;
			top: 43%;
		}

		.c09 {
			right: 54%;
			top: 43%;
		}

		.c10 {
			right: 58%;
			top: 43%;
		}

		.c11 {
			right: 62%;
			top: 48%;
		}

		.c12 {
			right: 46%;
			bottom: 22%;
		}

		.c13 {
			right: 58.5%;
			top: 60%;
		}

		.c14 {
			left: 36%;
			top: 14%;
		}

		.c15 {
			left: 28%;
			bottom: 48%;
		}

		.c16 {
			left: 19%;
			bottom: 50%;
		}

		.c17 {
			left: 19%;
			bottom: 25%;
		}

		.c18 {
			left: 8%;
			bottom: 47%;
		}
	}

	&--6f {
		.c01 {
			right: 32%;
			top: 30%;
		}

		.c02 {
			right: 32%;
			top: 60%;
		}

		.c03 {
			right: 26%;
			bottom: 16.8%;
		}

		.c04 {
			right: 40%;
			bottom: 10%;
		}

		.c05 {
			right: 47%;
			top: 33%;
		}

		.c06 {
			right: 44%;
			bottom: 34%;
		}

		.c07 {
			right: 56%;
			top: 33%;
		}

		.c08 {
			right: 54%;
			bottom: 34%;
		}

		.c09 {
			right: 61%;
			top: 24%;
		}

		.c10 {
			right: 65%;
			top: 24%;
		}

		.c11 {
			right: 63%;
			top: 33%;
		}

		.c12 {
			right: 60%;
			top: 55%;
		}

		.c13 {
			left: 38%;
			bottom: 24%;
		}

		.c14 {
			left: 33%;
			bottom: 24%;
		}

		.c15 {
			left: 27%;
			top: 30%
		}

		.c16 {
			right: 68%;
			top: 57%;
		}

		.c17 {
			left: 19%;
			top: 30%;
		}

		.c18 {
			left: 22%;
			bottom: 27%;
		}

		.c19 {
			left: 13%;
			top: 22%;
		}

		.c20 {
			left: 8%;
			top: 22%;
		}

		.c21 {
			left: 10%;
			top: 33%;
		}

		.c22 {
			left: 12%;
			bottom: 36%;
		}

		.c23 {
			left: 7%;
			bottom: 39%;
		}

		.c24 {
			left: 5%;
			bottom: 28%;
		}

		.c25 {
			left: 6%;
			bottom: 16%;
		}

		.c26 {
			left: 3%;
			top: 20%;
		}
	}

	&--7f {
		.c01 {
			right: 28.5%;
			bottom: 45%;
		}

		.c02 {
			right: 47%;
			top: 28%;
		}

		.c03 {
			right: 28%;
			bottom: 20%;
		}

		.c04 {
			right: 36%;
			bottom: 20%;
		}

		.c05 {
			right: 44%;
			bottom: 20%;
		}

		.c06 {
			right: 60%;
			bottom: 20%;
		}

		.c07 {
			right: 65%;
			top: 30%;
		}

		.c08 {
			right: 75%;
			top: 30%;
		}
	}

}