// u-display:

.u-dsp {
    &__block {
        display: block;
    }

    &__inline {
        display: inline;
    }

    &__inline-block {
        display: inline-block;
    }

    &__flex {
        display: flex;

        &--wrap {
            flex-wrap: wrap;
        }

        &--jfc-fs {
            justify-content: flex-start;
        }

        &--jfc-c {
            justify-content: center;
        }

        &--jfc-sbt {
            justify-content: space-between;
        }

        &--jfc-sad {
            justify-content: space-around;
        }

        &--jfc-end {
            justify-content: flex-end;
        }

        &--alitemCenter {
            align-items: center
        }
    }

    &__inflex {
        display: inline-flex;
    }

    &__order {
        &1 {
            order: 1;
        }

        &2 {
            order: 2;
        }

        &3 {
            order: 3;
        }

        &4 {
            order: 4;
        }

        &5 {
            order: 5;
        }

        &6 {
            order: 6;
        }
    }

    &__none {
        &--sp {
            display: none;
        }
    }

    &__imgSp {
        display: block;
    }

    &__imgPc {
        display: none;
    }
}

@include mq-u(md) {
    .u-dsp {
        &__none {
            &--sp {
                display: block;
            }
        }

        &__imgSp {
            display: none;
        }

        &__imgPc {
            display: block;
        }
    }

}

@include mq-d(md) {
    .u-display {
        &__elem {
            &--inlineBlock {
                margin-right: 1rem;

                .l-main__index--map & {
                    margin-right: .5rem;
                }
            }
        }
    }

    &__none {
        &--sp {
            display: none;
        }
    }
}