/* アントデザインのモーダルのヘッダーデザイン */
.ant-modal-title {
    color: white !important;
}

.ant-modal-header {
    background: rgba(0, 58, 115, 0.839) !important;
}

.ant-modal-close-icon {
    color: white !important;
}

/* 画面サイズが641pxまでの場合のモーダルデザイン */
@media screen and (max-width: 641px) {
    .ant-modal-centered .ant-modal {
        vertical-align: unset !important;
        width: 90% !important;
        top: 5rem !important;
    }

    .ant-modal-wrap {
        overflow: unset !important;
    }
}

/* アントデザインのスライダーのデザイン */
.ant-slider-handle {
    border: solid 2px rgba(0, 58, 115, 0.839) !important;
}

.ant-slider .ant-slider-track {
    background-color: rgba(0, 58, 115, 0.839) !important;
}

.ant-slider-handle:focus {
    box-shadow: none !important;
}

/* アントデザインのモバイルメニューのデザイン */
.ant-drawer-header-close-only {
    padding: 0 !important;
}

.ant-drawer-header-title {
    display: none !important;
}

.ant-drawer-body {
    padding: 0 !important;
}

.ant-drawer .ant-drawer-content {
    height: auto !important;
}

.ant-drawer-right {
    margin-top: 3.7rem !important;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
    box-shadow: none !important;
}

/* アントデザインのフォームアイテムのデザイン */
.ant-form-item {
    margin: 0 !important;
}

/* アントデザインのモーダルのデザイン */
.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
    display: none !important;
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
    display: none !important;
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
    display: none !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    display: none !important;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
    margin-left: unset !important;
}