@charset "utf-8";

/* ===================================================================
  Theme Name:
  Description:
  Theme URL:
  Author:
  Author URI:

  Version:    1.0.0
=================================================================== */
/**
* Table of Contents:
*
* 1.  Base Style
* 2.  Common Style
* 3.  Header Style
* 4.  Section Style
* 5.  Footer Style
* 6.  Navi Style
*/
/* Base Style
=================================================================== */


html {
    width: 100%;
    background-size: contain;
    font-size: 85%;
    letter-spacing: .07em;
    box-sizing: border-box;
    overflow-x: hidden;
    color: $clrBase02;

    &.wf-active {
        visibility: visible;
    }

    // @include mq-u(sm) {
    //     font-size: 85%;
    // }

    @include mq-u(md) {
        font-size: 93%;
    }

    // @include mq-u(lg) {
    //     font-size: 100%;
    // }
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

// @import url(//fonts.googleapis.com/css?family=Noto+Serif+JP);
@import url(//fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,800);

body {
    // @include fontPrefix(("ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif));

    @include fontPrefix(("Noto Sans Japanese", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ'));

    //  background: url(#{$pathImg}share/bg.png) no-repeat fixed center;
    background: $clrBody;
    background-size: 100%;

    /* アンチエイリアスを指定する */
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    /* メトリクスカーニングを指定する */

    margin: 0;
    padding: 0;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0;
    position: relative;
    font-size: unset; // 梅田が追加
    // overflow: hidden; // 梅田がコメントアウト

    &.open {
        left: 0;
        right: 0;
    }
}

a {
    text-decoration: none;
    transition: all .3s ease-in-out;

    &:hover {
        // color: $clrAnchorHover;
        text-decoration: none;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: none;
        outline: none;
    }

    &.link-tel {
        color: $clrBlack;

        @include mq-d(md) {
            color: $clrAnchor;
        }
    }

    &.link-text {
        color: $clrBlack !important;
        display: inline !important;
        text-decoration: none !important;
    }
}

*::selection {
    background: $clrBase01;
    color: #fff;
}

svg {
    transition: all .3s ease-in-out;
}

svg.none {
    display: none;
}

.container {
    width: 100%;
}

.contents {
    width: 100%;
}


button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;


    &:focus {
        outline: none;
    }
}

input,
img {
    outline: none;
}

select {
    backface-visibility: hidden;

}

input,
button,
select {
    font-family: "Noto Sans Japanese", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ';
}

.aps-mfp-close {
    background: #8c0033;
    display: block;
    height: 5rem;
    padding: .5rem 1.2rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    line-height: 1.4;
    color: inherit;
}

h1 {}

h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

ul,
ol {
    padding-left: 1em;
}

li {
    list-style: none;

    >ul,
    >ol {
        margin-bottom: 0;
    }
}

dd {
    margin-left: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

[src$=".svg"],
[data$=".svg"] {
    width: 100%;
}

i,
cite,
em,
address,
dfn {
    font-style: normal;
}

code,
pre {
    font-family: Consolas, Menlo, Courier, monospace;
}

code {
    padding: 0.2em;
    background-color: rgba(0, 0, 0, 0.06);
}

pre code {
    padding: 0;
    background-color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
    margin-top: 0;
    margin-bottom: 0;
}

.DIN {
    font-family: "DIN Condensed";
}

.container {
    max-width: 960px;
    margin: 0 auto;
}

.copyright {
    font-family: 'Poppins', sans-serif;
    padding: .2rem;
    background: $clrBase01;
    color: $clrWhite;
    text-align: center;

    small {
        font-size: .8rem;
        width: 1rem !important;
    }
}

@include mq-u(md) {
    .copyright {
        float: right;
    }

    .pageTop {
        &Area {
            .pageTop {
                a {
                    right: -85px;
                    bottom: 0;
                    padding: 0;
                    width: 70px;
                    height: 70px;

                    &::before {
                        top: 2px;
                    }
                }
            }
        }
    }
}

@include mq-d(md) {
    .br-pc {
        display: none;
    }
}

@include mq-u(md) {
    .br-sp {
        display: none;
    }
}