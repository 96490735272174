/* BreadCrumb
=================================================================== */
.p-breadcrumb {
	display: block;
	padding: .5rem;
	@include clearfix;
	position: relative;
	background: rgba(17, 151, 96, .15);
	left: 0;
	z-index: 110;
	width: 100%;
	top: 0;
	font-size: .8rem;

	&__list {
		margin: 0 auto;
		color: $clrGray02;
		max-width: 72rem;
	}

	&__item {
		float: left;
		font-weight: 200;

		a {
			color: #165f2a;

			&:hover {
				color: #0c86d3;
				text-decoration: underline;
				text-decoration: none;
			}
		}

		&::after {
			margin: auto .5rem;
			content: "";
			border-bottom: 1px solid #333;
			border-right: 1px solid #333;
			display: inline-block;
			width: .6rem;
			height: .6rem;
			transform: rotate(-45deg);
		}

		&:last-child {
			&::after {
				content: none;
			}
		}
	}
}


@include mq-u(md) {
	.p-breadcrumb {
		padding: .5rem;
		font-size: .9rem;

		&__list {}

		&__item {
			a {
				&:hover {}
			}

			&::after {}

			&:last-child {
				&::after {}
			}
		}
	}
}