/* background style
=================================================================== */

.u-bg {
	&__base {
		background: $clrBase01;
	}

	&__white {
		background: $clrWhite;
	}

	&__red {
		&01 {
			background: $clrRed01;
		}

		&02 {
			background: $clrRed02;
		}
	}

	&__gray {
		background: #f2f2f2;
	}
}