/* padding style
=================================================================== */

$num: 1;

@for $i from 0 through 100 {
  .u-pt#{$i * $num} {
    padding-top: #{$i * $num}px !important;
  }

  .u-pr#{$i * $num} {
    padding-right: #{$i * $num}px !important;
  }

  .u-pb#{$i * $num} {
    padding-bottom: #{$i * $num}px !important;
  }

  .u-pl#{$i * $num} {
    padding-left: #{$i * $num}px !important;
  }
}