// button.scss
.c-btn {
  &__pageTop {
    position: fixed;
    background: rgba(233, 68, 65, 0.8);
    color: $clrWhite;
    z-index: 90;
    display: flex;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    right: 2%;
    bottom: -20%;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    z-index: 70;

    &--icon {
      width: 1.6rem;
      height: 1.6rem;
      margin: auto;
      display: block;
    }

    &:hover {
      box-shadow: 0 0 3px $clrBase02;
      transform: scale(0.9, 0.9);
      color: $clrWhite;

      svg {
        fill: $clrWhite;
      }
    }
  }

  &__send {
    background: #DF0039;
    color: #fff;
    display: block;
    margin: 2rem auto;
    font-size: 1.25rem;
    padding: 1.5rem 2rem;
    border-radius: 3px;
    font-weight: 700;
    white-space: nowrap;
    transition: all .3s ease;

    &:hover {
      background: #96092c;
    }
  }

  &__Aitest {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #DF0039;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 5px;

    &:hover {
      background: #96092c;
    }

    span {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      margin: 0 auto .25rem;
      width: 7rem;
    }
  }

}

@include mq-u(sm) {}

@include mq-u(md) {
  .c-btn {
    &__pageTop {
      width: 5rem;
      height: 5rem;
      right: 2%;
    }

    &__area {
      width: 80%;
      display: flex;

    }
  }
}