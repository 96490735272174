/* icon style
=================================================================== */


.c-icon {
	display: inline-block;

	&__szSs {
		display: inline-block;
		width: 1rem;
	}

	&__szS {
		display: inline-block;
		width: 1.4rem;
	}

	&__szM {
		display: inline-block;
		width: 2rem;
	}

	&__szL {
		display: inline-block;
		width: 3rem;
		height: 3rem;
	}

	&__szLL {
		display: inline-block;
		width: 5rem;
		height: 5rem;
	}

	&__szXL {
		display: inline-block;
		width: 7rem;
		height: 7rem;
	}

	&__szWh50p {
		display: inline-block;
		width: 50%;
	}

	&__szWh20p {
		display: inline-block;
		width: 20%;
	}

	&__szbtnSp {
		display: block;
		width: 2rem;
		height: 1.2rem;
		margin: auto;
	}

	&Svg {
		width: 100%;
		height: 100%;
	}

	&__txtlink {
		display: inline-flex;
		align-items: center;
		white-space: nowrap;

		svg {
			fill: $clrAnchor;
		}

		&:hover {
			svg {
				fill: $clrAnchorHover;
			}
		}
	}

	&__clr {
		&--gray05 {
			fill: $clrGray05;
		}

		&--gray06 {
			fill: $clrGray06;
		}

		&--gray07 {
			fill: $clrGray07;
		}

		&--gray08 {
			fill: $clrGray08;
		}

		&--gray09 {
			fill: $clrGray09;
		}

		&--footer {
			fill: $clrfootertxt;
		}

		&--white {
			fill: $clrWhite;
		}

		&--white-8 {
			fill: $clrWhite-8;
		}

		&--whitegreen {
			fill: $clrWhiteGreen;
		}

		&--base01 {
			fill: $clrBase01;
		}

		&--base03 {
			fill: $clrBase03;
		}

		&--black {
			fill: $clrBlack;
		}

		&--yellow {
			fill: $clrYellow;
		}

		&--org {
			fill: $clrOrange;
		}

		&--logogreen {
			fill: $clrLogoGreen;
		}

		&--greenblue {
			fill: $clrGreenBlue;
		}

		&--blue {
			fill: $clrBlue-n;
		}

		&--blueico {
			fill: $clrBlueico;
		}

		&--bluesky {
			fill: $clrskyBlue;
		}

		&--pageTitle {
			fill: $clrPageTitle;
		}

		&--grayblue {
			fill: $clrGrayBlue;
		}

		&--lightskyblue {
			fill: $clrLightSkyBlue;
		}

		&--red {
			&01 {
				fill: $clrRed01;
			}

			&02 {
				fill: $clrRed02;
			}

			&03 {
				fill: $clrRed03;
			}
		}
	}
}


@include mq-u(md) {}

@include mq-d(md) {}