.l-content {
	&Txt {
		text-align: left;
		line-height: 1.8;
	}

	&__logoTit {
		&--mid {
			width: 16rem;
			display: block;
			margin: 2rem auto;
		}

		&--sml {
			width: 8rem;
			display: block;
			margin: 2rem auto 0;
		}
	}

	&__Aitest {
		display: block;
		padding: 1.5rem;
		background: rgba(255, 255, 255, 0.95);
		border-radius: 30px;
		margin: 1rem auto 5rem;
		box-shadow: 0 10px 30px rgba(10, 36, 122, .44);
		position: relative;

		&__Txt {
			text-align: left;

			&--red {
				color: #DF0039;
				font-weight: 500;
				font-size: 1.2rem;
			}
		}

		&__product {
			position: absolute;
			right: 0;
			bottom: -8rem;
			height: 10rem;
		}

	}

	&__uploadArea {
		display: none;
	}

	&__point {
		&01Box {
			margin: 1rem 0;
			position: relative;

			.l-content__pointImg {
				&--pc {
					display: none;
				}

				position: relative;
				left: 0;
				width: 50vw;
				margin-right: 50vw;
				margin-left: -1rem;
			}
		}

		&02Box {
			margin: 5rem 0;
			position: relative;

			.l-content__pointImg {
				&--pc {
					display: none;
				}

				position: relative;
				right: 0;
				width: 55vw;
				margin-left: 45vw;
				margin-right: -1rem;
			}

			.c-list__pointName {
				top: -11.5rem;
				right: auto;
				left: 0;
			}
		}

		&03Box {
			margin: 1rem 0;
			position: relative;

			.l-content__pointImg {
				&--pc {
					display: none;
				}

				width: 50vw;
				margin-left: -1rem;
			}

			.c-list__pointName {
				top: -11rem
			}
		}
	}

}


@include mq-u(md) {

	.l-content {
		&Txt {
			text-align: center;
		}

		&__Aitest {
			padding: 2rem;
			margin: 2rem auto 5rem;
			box-shadow: 0 10px 30px rgba(10, 36, 122, .44);

			&__Txt {
				text-align: center;
			}

			&__product {
				position: absolute;
				right: -5rem;
				bottom: -8rem;
				height: 20rem;

			}
		}

		&__uploadArea {
			display: block;
			background: #E2F2FF;
			border: 2px #2A7FFF dotted;
			color: #2A7FFF;
			padding: 3rem;
			max-width: 50rem;
			margin: 1rem auto;

			&Icon {
				display: block;
				margin: auto;
				width: 6rem;
				height: 6rem;
				padding: 1rem;
				background: #fff;
				border-radius: 100%;
			}
		}

		&__point {
			&01Box {
				margin: 1rem auto;
				position: relative;
				display: flex;
				max-width: 72rem;


				.c-list__point {
					left: 50%;
				}

				.l-content__pointImg {
					display: none;

					&--pc {
						display: block;
						position: absolute;
						left: 50%;
						width: 100vw;
						transform: translateX(-50%);

						.l-content__pointImg--pc01 {
							position: absolute;
							z-index: 10;
							left: 30%;
							top: -6.5rem;
							width: 42rem;
							transform: translateX(-50%);
							z-index: 10;
						}

						&::before {
							content: '';
							position: absolute;
							height: 32rem;
							width: 60vw;
							left: 0;
							top: 2rem;
							background: linear-gradient(to right, #51CAFF, #006BE6);
							z-index: 1;
						}

						&::after {
							content: '';
							position: absolute;
							height: 33rem;
							width: 60vw;
							top: 2rem;
							right: -1rem;
							background: #fff;
							transform: skewX(-18deg);
							z-index: 2;
						}

						.l-content__pointImg--pc03 {
							position: absolute;
							z-index: 10;
							left: 30%;
							top: -5rem;
							width: 48rem;
							transform: translateX(-50%);
							z-index: 10;
						}
					}
				}
			}

			&02Box {
				margin: 5rem auto;
				position: relative;
				display: flex;
				max-width: 72rem;

				.l-content__pointImg {
					display: none;

					&--pc {
						display: block;
						position: absolute;
						left: 50%;
						width: 100vw;
						transform: translateX(-50%);

						&::before {
							content: '';
							position: absolute;
							height: 32rem;
							width: 60vw;
							right: 0;
							top: 2rem;
							background: linear-gradient(to right, #205876, #00336d);
							z-index: 1;
						}

						&::after {
							content: '';
							position: absolute;
							height: 33rem;
							width: 60vw;
							top: 2rem;
							left: 2rem;
							background: #fff;
							transform: skewX(-18deg);
							z-index: 2;
						}

						.l-content__pointImg--pc02 {
							position: absolute;
							z-index: 10;
							right: 10%;
							top: 2rem;
							height: 32rem;
							transform: translateX(50%);
							z-index: 10;
							opacity: 0.5;
							mix-blend-mode: screen;
						}
					}
				}

				.c-list__point {
					order: 1;
					flex-shrink: 0;
					left: -5%;
				}

				.c-list__pointName {
					top: 0;
					right: auto;
					left: 0;
				}

			}

			&03Box {
				margin: 1rem auto;
				position: relative;
				display: flex;
				max-width: 72rem;


				.c-list__point {
					left: 50%;
				}

				.l-content__pointImg {
					display: none;

					&--pc {}
				}

				.c-list__pointName {
					top: 0;
					right: auto;
					left: 0;
				}

			}
		}
	}
}

@keyframes onmap {
	0% {
		transform: scale(.8);
		opacity: .9;
	}

	50% {
		transform: scale(1.6);
		opacity: .5;

	}

	100% {
		transform: scale(3.2);
		opacity: 0;
	}
}