/* size style
=================================================================== */

//font-size

$fs-min: 10;
$fs-max: 50;

@for $i from $fs-min through $fs-max {
  .u-fs#{$i} {
    font-size: #{$i}px;
  }
}


@for $i from 0 through 20 {
  $decimal: (0.1 * $i + 1rem);

  .u-fs1rem_#{$i} {
    font-size: $decimal;
  }
}


// width

$wh-min: 1;
$wh-max: 400;

@for $i from $wh-min through $wh-max {
  .u-wh#{$i} {
    width: #{$i}px;
  }
}

$whp-min: 1;
$whp-max: 100;

@for $i from $whp-min through $whp-max {
  .u-wh#{$i}p {
    width:#{$i}#{'%'};
  }
}


$htp-min: 1;
$htp-max: 100;

@for $i from $htp-min through $htp-max {
  .u-ht#{$i}p {
    height: #{$i}#{'%'};
  }
}

@for $i from $htp-min through $htp-max {
  .u-ht#{$i}vh {
    height: #{$i}vh;
  }
}

.u-whAuto {
  width: auto;
  min-width: auto;
  max-width: auto;
}

.u-whmin1024 {
  min-width: 1024px;
}


@for $i from 1 through 20 {
  $width: percentage(1 / $i);

  .u-col#{$i} {
    width: $width;
  }
}

//height

$ht-min: 1;
$ht-max: 700;

@for $i from $ht-min through $ht-max {
  .u-ht#{$i} {
    height: #{$i}px;
  }
}

.u-ht--minAuto {
  min-height: auto;
}

.u-ht--maxAuto {
  max-height: auto;
}

@include mq-u(md) {

  @for $i from $whp-min through $whp-max {
    .u-wh#{$i}p-pc {
      width: #{$i}#{'%'};
    }
  }

  @for $i from $wh-min through $wh-max {
    .u-wh#{$i}-pc {
      width: #{$i}px;
    }
  }

  .u-wh1100-pc {
    max-width: 1100px;
    width: 1100px;
  }
}