/* gallery
=================================================================== */
.p-gallery {
	&__photo {
		position: relative;
		margin: 10px auto;
		max-width: 42rem;
		background: #fff;
		padding: 1rem;
		display: flex;
		border-radius: 12px;
		justify-content: center;
		flex-wrap: wrap;
		box-shadow: 0 0 20px #000;

		img {
			order: 2;
		}

		&Name {
			width: 100%;
			order: 1;
			padding: .5rem 0;
			color: #10ac97;
		}
	}

	&__fig {
		display: block;
		margin: 0 auto;
		padding: 0 0 .8rem;

		&Txt {
			padding: .5rem 0;
			color: #08ae91;
		}

		&Area {
			padding: .5rem 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			.p-gallery__fig {
				width: calc(50% - .5rem);
				margin-right: .5rem;
				margin-left: 0;
			}
		}
	}
}

@include mq-u(md) {
	.p-gallery {
		&__photo {
			padding: 1.2rem;

			img {
				order: 2;
			}

			&Name {
				width: 100%;
				order: 1;
				padding: .5rem 0;
				font-size: 1.2rem;
			}
		}

		&__fig {

			&Txt {
				padding: .5rem 0;
				color: #08ae91;
			}

			&Area {

				display: flex;
				flex-wrap: nowrap;

				.p-gallery__fig {
					width: auto;
					margin-right: .5rem;
				}
			}
		}
	}
}