// form.scss

.c-form {
  &__input {
    border: 1px solid #ccc;
    padding: .5rem .5rem;
    width: 100%;
    height: 3rem;
    border-radius: 6px;
    margin: .5rem auto 0;
    font-family: "Noto Sans Japanese", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ';
  }

  &__area {
    border: 1px solid #ccc;
    padding: .5rem;
    width: 100%;
    height: 10rem;
    resize: none;
    border-radius: 6px;
    margin: .5rem auto;
    font-family: "Noto Sans Japanese", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ';

    &:focus {
      outline: none;
    }
  }

  &__btn {
    margin: 1rem auto;
    font-family: "Noto Sans Japanese", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ';
    color: #511212;
    background: #f3c300;
    box-shadow: 0 5px 0 #e5a316;
    padding: 1rem 2rem;
    width: 100%;
    min-width: 18rem;
    font-size: 1.2rem;
    border-radius: 2rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: 500;
    transition: all .3s ease;

    &.edit {
      color: #fff;
      background: #f37900;
      box-shadow: 0 5px 0 #e55e16;

      &:hover {
        color: #fff;
        background: #e55e16;
      }
    }

    &.send {
      color: #fff;
      background: #0f61b4;
      box-shadow: 0 5px 0 #112ea8;

      &:hover {
        color: #fff;
        background: #112ea8;
      }
    }

    &:hover {
      margin-top: calc(1rem + 8px);
      color: #511212;
      background: #e5a316;
      box-shadow: 0 0 0 #e5a316;
      cursor: pointer;
    }

    &Area {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      height: 6rem;
      margin-top: 1rem;
    }
  }

  &__checkLabel {
    cursor: pointer;
    position: relative;
    padding: 0 0 0 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
    display: inline-block;

    &__Name {
      &::before {
        content: '';
        display: inline-block;
        border: 1px solid #0f61b4;
        background: #fff;
        border-radius: 3px;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: .1rem;
        left: 0;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        opacity: 0;
        width: .7rem;
        height: 1.2rem;
        border-right: 3px solid #0f61b4;
        border-bottom: 3px solid #0f61b4;
        transform: rotate(45deg);
        left: .5rem;
        top: -.2rem;
        z-index: 10;
      }
    }

    &__Txt {
      display: flex;
      align-items: center;

      &.must {
        &::after {
          content: '必須';
          background: #DF0039;
          color: #fff;
          display: inline-block;
          padding: .1rem .5rem .1rem;
          font-size: .8rem;
          border-radius: 3px;
          margin-left: .5rem;
        }
      }
    }

    input[type="checkbox"] {
      display: none;

      &:checked+.c-form__checkLabel__Name::after {
        opacity: 1;

      }
    }

  }
}

@include mq-u(md) {
  .c-form {
    &__input {}

    &__area {}

    &__btn {
      width: 10rem;
    }

    &__checkLabel {
      margin: 1rem auto;
    }
  }
}