.l-header {
    width: 100%;
    height: 4.5rem;
    padding: .5rem 1rem;
    position: fixed;
    z-index: 10000;
    transition: all .3s ease;
    top: 50px; // 梅田が追加→美濃が追加

    .u-dsp__flex {
        justify-content: end;
    }

    &.bg {
        background: #fff;
        box-shadow: 0 3px 20px rgba(0, 0, 0, .35);
    }

    &.first {
        background: rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 rgba(0, 0, 0, .35);

        .c-list__gnavLink {
            color: #fff;
        }

        .c-icon__clr--base01 {
            fill: #fff;
        }

        .l-header__navi--spMenuLine {
            background: #fff;
        }

        .l-header__navi--spMenuTxt {
            color: #fff;
        }
    }

    &__logo {
        width: 8rem;
        position: absolute;
        left: 1rem;
        margin-top: .5rem;
    }

    &__navi {
        &--pc {
            display: none;
        }

        &--sp {
            height: 4.5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            right: .5rem;
            z-index: 2000;

            &Menu {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                border-radius: 6px;
                margin: .25rem -.5rem 0 0;
                width: 3rem;
                height: 3rem;
                padding: .6rem .4rem 0 .4rem;
                cursor: pointer;
                font-size: .5rem;
                color: $clrBase01;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                position: relative;

                &Txt {
                    font-weight: 700;
                    margin-top: -.5rem;

                    &::before {
                        content: 'MENU';
                        font-weight: 500;
                    }
                }

                &Line {
                    display: block;
                    width: 2.5rem;
                    height: 3px;
                    margin-top: -.3rem;
                    background: $clrBase01;
                    transition: all .3s ease;
                }

                &.open {
                    background: #0C1867;
                    border-color: #0C1867;
                    padding-top: .6rem;
                    color: $clrWhite;
                    height: 3rem;

                    .l-header__navi--spMenuLine {
                        display: block;
                        width: 1.49rem;
                        height: 2px;
                        background: $clrWhite;
                        margin: 0 auto;

                        &:first-child {
                            transform: rotate(45deg);
                            position: absolute;
                            top: 39%;

                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:nth-child(3) {
                            transform: rotate(-45deg);
                            position: absolute;
                            top: 39%;
                        }
                    }

                    .l-header__navi--spMenuTxt {
                        margin-top: .8rem;

                        &::before {
                            content: 'CLOSE';
                        }
                    }

                    .l-header__naviListSp {
                        visibility: visible;
                        opacity: 1;
                        overflow: auto;
                        padding-bottom: 5rem;
                    }
                }
            }

            &Tel {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                padding: .5rem;
                margin: .25rem .5rem 0 0;
                width: 3rem;
                height: 3rem;
            }

            &Btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                border: 2px solid $clrGreenBlue;
                border-radius: 5px;
                margin: .9rem .4rem .8rem;
                width: 3.5rem;
                height: 3.5rem;
                padding: .4rem .4rem 0 .4rem;
                cursor: pointer;
                font-size: .4rem;
                color: $clrGreenBlue;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
            }

            &Icon {
                width: 2rem;
                height: 2rem;
                align-self: flex-start;
            }
        }

        &ListSp {
            align-items: center;
            font-weight: 300;
            padding: 0;
            width: 100%;
            margin: 0 auto;
            top: 4.5rem;
            left: 0;
            background: rgba(8, 7, 57, 0.7);
            z-index: 9999;
            max-height: calc(100vh - 45px);
            // height: 100vh;      // 梅田がコメントアウト
            // position: absolute; // 梅田がコメントアウト
            // visibility: hidden; // 梅田がコメントアウト
            // opacity: 0;         // 梅田がコメントアウト

            &Item {
                position: relative;
                border-bottom: 1px solid #143c7d;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                background: #0a55ac;
                z-index: 999;

                &Icon {
                    display: inline-block;
                    width: .5rem;
                    margin-top: -.1rem;
                    margin-right: .5rem;
                }

                &--link {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    padding: 1rem 1.5rem 1rem 1rem;
                    white-space: nowrap;
                    color: $clrWhite;
                    font-size: 1.2rem;
                    font-weight: 500;
                    width: calc(100% - 5rem);
                    height: 100%;
                    z-index: 100;
                    cursor: pointer;

                    &__eng {
                        font-weight: 200;
                        font-size: .8rem;
                        margin-left: 1.2rem;
                        margin-top: .15rem;
                    }

                    &.other {
                        width: 100%;

                    }

                    &.freetel {
                        width: 100%;
                        background: #280f98;

                    }

                    &:hover {
                        color: $clrWhite;
                        background: #280f98;
                    }

                    &.link_on {
                        color: $clrAnchor;
                        margin-right: 1.1rem;

                        &::after {
                            display: none;
                        }
                    }

                    &.about {
                        &::before {
                            content: '';
                            width: .5rem;
                            height: .5rem;
                            margin: .2rem .5rem 0;
                            border-radius: 100%;
                            background: #fff;
                        }
                    }
                }

                &--bg {
                    display: none;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100vh;
                    background: rgba(80, 92, 105, 0.6);
                    z-index: 10;
                }

                &--sub {
                    &List {
                        height: 0;
                        overflow: hidden;
                        width: 100%;
                        z-index: 70;
                        padding: 0;
                        margin: 0;
                        background: rgb(12, 132, 116);
                        transition: all ease .4s;

                        &Item {
                            position: relative;
                            width: 100%;

                            &::before {
                                content: '';
                                background: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                left: 0;
                                bottom: 0;
                            }
                        }

                        &.open {
                            display: block;
                        }

                        &Open {
                            &.close {
                                &::after {
                                    transform: rotate(0);
                                }
                            }

                            display: inline-block;
                            width: 4rem;
                            height: 1rem;
                            margin: 1rem auto;
                            position: relative;
                            cursor: pointer;
                            z-index: 70;
                            margin-right: 1rem;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 1.2rem;
                                height: 2px;
                                background: #fff;
                                position: absolute;
                                right: .66rem;
                                top: .5rem;
                            }

                            &::after {
                                content: '';
                                display: inline-block;
                                width: 1.2rem;
                                height: 2px;
                                background: #fff;
                                position: absolute;
                                right: .66rem;
                                top: .5rem;
                                transform: rotate(-270deg);
                                transition: all .3s ease;
                            }
                        }

                        &Link {
                            color: $clrWhite;
                            width: 100%;
                            display: block;
                            text-align: left;
                            font-weight: 300;
                            border-top: 1px solid #11bda6;
                            padding: .7rem 1rem;
                            background: #147D6F;

                            &::before {
                                content: '';
                                display: inline-block;
                                border-right: 2px solid #fff;
                                border-bottom: 2px solid #fff;
                                width: .65rem;
                                height: .65rem;
                                margin: 0 .5rem .1rem .5rem;
                                transform: rotate(-45deg);
                            }

                            &Inner {
                                color: $clrWhite;
                                width: 100%;
                                display: block;
                                text-align: left;
                                font-weight: 300;
                                border-bottom: 1px solid $clrWhite-8;
                                padding: .3rem 1.5rem;

                                &:last-child {
                                    border-bottom: none;
                                    ;
                                }

                                &::before {
                                    content: '';
                                    width: .8rem;
                                    height: .8rem;
                                    margin: auto .2rem;
                                    display: inline-block;
                                    background: url(../svg/icon_triangle.svg)no-repeat center center;
                                }
                            }
                        }
                    }
                }
            }
        }

        &List {
            padding: 0;
            transition: all ease .5s;
        }
    }

}

.fig{
  align-items: center;
  background: #f4f9fd;
  display: flex;
  height: 50px;
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10001;
  >div{
    padding: 0 17px;
    margin: 0 auto;
    width: 100%;
    >div{      
      align-items: center;
      display: flex;
      column-gap: 10px;
      justify-content: flex-end;
      a{
        height: 20px;
        display: block;
      }
    }
  }
}

@include mq-u(md) {
    .l-header {
        padding: 0;

        &__logo {
            width: 10rem;
            margin-top: .5rem;
        }

        // 以下は梅田が追加
        &__navi {
            &--sp {
                &Tel {
                    display: none;
                }

                &Menu {
                    display: none;
                }
            }
        }

        // ここまでは梅田が追加
    }

    .fig{
      >div{
        padding: 0 34px;
      }
    }
  
}