/* table style
=================================================================== */

/* table */

.c-table {
	&__time {
		margin: 2rem auto 1rem;
		max-width: 64rem;
		width: 100%;
		background: #eef6ed;
		border-collapse: separate;
		border-spacing: 0;
		border-radius: 12px;
		border: 3px solid #08ae91;
		display: flex;
		font-size: .9rem;
		position: relative;

		thead {

			tr {}

			th {}
		}

		tbody {
			display: flex;
			width: 100%;

			tr {
				width: 100%;
			}

			td {}
		}

		td,
		th {
			border-right: 1px solid #08ae91;
			border-bottom: 1px solid #08ae91;

		}

		&Item {
			display: none;
			color: #0c7764;
			background: #deeddc;
			border-radius: 12px 0 0 0;
			text-align: center;
			padding: 1rem .5rem;
			height: calc(100% - 24rem);
		}

		&Day {
			display: flex;
			color: #08ae91;
			justify-content: center;
			align-items: center;
			height: 4rem;
			width: 3rem;

			&:nth-child(2) {
				margin-top: 8.6rem;
			}
		}

		&Schedule {
			position: absolute;
			padding: .5rem;
			z-index: 10;
			width: 100%;
			top: 0;
			left: 0;
			background: #eef6ed;
			border-radius: 9px 9px 0 0;
		}

		&Am,
		&Pm {
			color: #08ae91;
			display: flex;
			align-items: center;
			margin: .5rem auto;

			&Time {
				display: block;
				font-weight: 300;
				background: #0cc794;
				color: #fff;
				border-radius: 6px;
				padding: .5rem;
				margin: 0 .5rem;
			}
		}

		&DrName {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			background: #fff;
			vertical-align: top;
			border-bottom: none;
			padding: .5rem;
			height: 4rem;

			&:nth-child(2) {
				margin-top: 8.6rem;
			}

			&:last-child {
				border-radius: 0 0 9px 0;
			}

			span {
				display: inline-block;
				padding: 0 .5rem;

			}
		}
	}

	&__price {
		border-collapse: separate;
		border-spacing: 0;
		margin: 0 0 2rem;
		width: 100%;
		border-top: 1px solid #5e1e1d;
		border-right: 1px solid #5e1e1d;
		font-size: 1rem;

		td,
		th {
			border-bottom: 1px solid #5e1e1d;
			border-left: 1px solid #5e1e1d;
			padding: .25rem .5rem;
		}

		&__itemName {
			text-align: center;
			padding: .25rem .5rem;
			background: #8e2928;
			color: #fff;
			font-size: .9rem;
		}

		&__Num {
			text-align: center;

			span {
				background: #8e2928;
				font-size: .8rem;
				color: #fff;
				padding: .5rem;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 1.6rem;
				height: 1.6rem;
				margin: auto;

			}
		}

		&__place {
			&::after {
				content: '㎡';
			}
		}

		&__use {
			text-align: right;

			&::before {
				content: '￥'
			}
		}
	}
}

@include mq-u(md) {
	.c-table {
		&__time {
			display: table;
			position: inherit;

			thead {
				tr {
					display: table;
					width: 100%;
				}

				th {
					display: table-cell;

				}
			}

			tbody {
				display: table-cell;
				width: auto;

				tr {
					display: table;
					width: 100%;
				}

				td {
					display: table-cell;
				}
			}

			&Item {
				display: table-cell;
				width: 15rem;
			}

			&Day {
				display: table-cell;
				width: auto;
				text-align: center;

				&:nth-child(2) {
					margin-top: auto;
				}
			}

			&Schedule {
				position: inherit;
				display: table-cell;
				border-radius: 0 0 0 9px;
				width: 15rem;
			}

			&Am,
			&Pm {
				display: block;
				text-align: center;

				&Time {
					display: block;
					margin: .5rem auto 1rem;
				}
			}

			&DrName {
				display: table-cell;

				&:nth-child(2) {
					margin-top: 0;
				}

				span {
					display: block;
					margin: auto;
					text-align: center;
				}
			}
		}

		&__price {}

	}
}