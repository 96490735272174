/* margin style
=================================================================== */

.u-m {

  &0auto {
    margin: 0 auto;
  }

  &-all0 {
    margin: 0;
  }

  &Auto {
    margin: auto;
  }
}

$num: 1;

@for $i from 0 through 120 {
  .u-mt#{$i * $num} {
    margin-top: #{$i * $num}px !important;
  }

  .u-mr#{$i * $num} {
    margin-right: #{$i * $num}px !important;
  }

  .u-mb#{$i * $num} {
    margin-bottom: #{$i * $num}px !important;
  }

  .u-ml#{$i * $num} {
    margin-left: #{$i * $num}px !important;
  }
}

@for $i from 0 through 100 {
  .u-mt#{$i * $num}p {
    margin-top: #{$i * $num}% !important;
  }

  .u-mr#{$i * $num}p {
    margin-right: #{$i * $num}% !important;
  }

  .u-mb#{$i * $num}p {
    margin-bottom: #{$i * $num}% !important;
  }

  .u-ml#{$i * $num}p {
    margin-left: #{$i * $num}% !important;
  }
}

@media screen and (max-width: 769px) {}

@include mq-u(md) {
  @for $i from 0 through 120 {
    .u-mt-pc#{$i * $num} {
      margin-top: #{$i * $num}px;
    }

    .u-mr-pc#{$i * $num} {
      margin-right: #{$i * $num}px;
    }

    .u-mb-pc#{$i * $num} {
      margin-bottom: #{$i * $num}px;
    }

    .u-ml-pc#{$i * $num} {
      margin-left: #{$i * $num}px;
    }
  }
}