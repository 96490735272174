 .l-main {
   position: relative;
   z-index: 10;
   margin: 0;
   padding: 0;
   display: block;
   width: 100%;
   overflow-x: hidden; // 梅田が追加
 }

 @include mq-u(md) {
   .l-main {}
 }