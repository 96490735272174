/* float style
=================================================================== */

.u-fl-l {
	float: left;
}

.u-fl-c {
	@include clearfix;
}

.u-fl-r {
	float: right;
}