.l-section {

	&__firstView {
		position: relative;
		height: 70vh;
		margin-top: 3rem;
		margin-bottom: 9rem;

		@media screen and (max-width:769px) and (min-width:415px) {
			margin-bottom: 18rem;
		}

		&__minvpc {
			display: none;
		}

		&__minvsp {
			display: block;
			width: 100%;
			position: absolute;
			top: -3rem;
			z-index: 1;

			@media screen and (max-width:769px) and (min-width:415px) {
				height: 100vh; // 梅田が追加
				overflow-y: hidden; // 梅田が追加
			}
		}

		&__productImg {
			position: absolute;
			z-index: 10;
			top: 18%;
			left: 1rem;
		}

		&__catch {
			color: #fff;
			position: absolute;
			// bottom: -3rem;
			top: 65%;
			right: 5rem;
			text-align: left;
			line-height: 1;
			transform: scale(1.2);
			z-index: 100;


			&--col01 {
				font-size: 1.5rem;
				display: block;
				font-weight: bold; // 梅田が追加
			}

			&--col02 {
				display: block;
				font-size: 3rem;
				margin: .5rem 0 1rem;
				line-height: 1.5;
				font-weight: bold; // 梅田が追加

				span {
					display: inline;
					position: relative;
					border-bottom: 5px solid #DF0039;

					&::before {
						content: "";
						position: absolute;
						background: #DF0039;
						width: .5rem;
						height: .5rem;
						border-radius: 100%;
						left: 50%;
						transform: translateX(-50%);

					}
				}
			}

			&--col03 {
				font-size: 1.2rem;
				font-weight: 500;
			}
		}
	}

	&__contentArea {
		max-width: 72rem;
		padding: 3rem 1rem;
		display: block;
		margin: auto;
		position: relative;

		@media screen and (max-width:769px) and (min-width:415px) {
			overflow: hidden; // 梅田が追加
			height: 100%; // 梅田が追加
		}

		&.nowidth {
			max-width: 100%;
		}

		&.bg {
			&Gray {
				&::after {
					content: '';
					display: block;
					width: 100vw;
					height: 100%;
					background: #F2F5F8;
					position: absolute;
					background-size: 100%;
					transform: translateX(-50%);
					left: 50%;
					top: 0;
					z-index: -1;
					;
				}
			}

			&sec01 {
				&--pc {
					display: none;
				}

				&--sp {
					display: block;
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					z-index: -1;
					width: 100%;

				}
			}

			&sec02 {
				&::after {
					content: '';
					display: block;
					width: 100vw;
					height: 100%;
					background: url(../img/sec02bg.png)top center no-repeat;
					position: absolute;
					background-size: cover;
					transform: translateX(-50%);
					left: 50%;
					top: 0;
					z-index: -1;
					;
				}
			}
		}

		&.inner-space {
			margin-top: 7rem;
		}

		&__Tit {
			font-size: 2.6rem;
			font-weight: 700;
			color: #0C0D11;

			&__Aitest {
				color: #fff;
				font-size: 2.6rem;
				display: flex;
				justify-content: center;
				align-items: baseline;
				margin: 0 auto;
				font-weight: bold; // 梅田が追加
				opacity: 95%; // 梅田が追加

				img {
					display: inline-block;
					width: 3.8rem;
					margin: 0 .5rem;
				}

				&--sub {
					color: #fff;
				}
			}
		}

		&__pickupName {
			color: #485e2c;
			margin: 7.5rem auto 2rem;
			font-size: 2rem;
			font-weight: 500;
		}

		&.contact {
			position: relative;

			&::after {
				content: '';
				display: block;
				width: 100vw;
				height: 100%;
				background: url(../img/form_bg.png)top center no-repeat;
				position: absolute;
				background-size: 100%;
				transform: translateX(-50%);
				left: 50%;
				top: 0;
				z-index: -1;
				;
			}
		}
	}
}

.successSendEmailTitle {
	text-align: center;
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 10px;

	.successSendEmailTitleIcon {
		color: #52c41a;
		font-size: 22px;
		margin: 0 5px;
	}
}

.successSendEmailContent {
	margin: 30px 0;
	font-size: 15px;
}

.errorSendEmailTitle {
	text-align: center;
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 10px;

	.errorSendEmailTitleIcon {
		color: #ff4d4f;
		font-size: 22px;
		margin: 0 5px;
	}

}

.errorSendEmailContent {
	margin: 30px 0;
	font-size: 15px;
}

@include mq-u(md) {
	.l-section {
		&__firstView {
			position: relative;
			height: 50rem;
			overflow: hidden;
			margin-top: 0;
			margin-bottom: 2rem;

			&__minvsp {
				display: none;
			}

			&__minvpc {
				display: block;
				height: 100%;
				position: absolute;
				right: -1.5rem;
				z-index: 1;
				object-fit: cover;
			}

			&__productImg {
				position: absolute;
				z-index: 10;
				width: 50rem;
				top: 18%;
				left: 10%;
			}

			&__catch {

				z-index: 100;
				bottom: auto;
				top: 30%;
				right: auto;
				left: 60%;
				text-align: left;
				line-height: 1.2;
				transform: scale(1.15);
				font-weight: 500;

				&--col01 {
					font-size: 2.4rem;
					display: block;
					font-weight: unset; // 梅田が追加
				}

				&--col02 {
					display: block;
					font-size: 5rem;
					margin: .5rem 0 1rem;
					font-weight: unset; // 梅田が追加

					span {

						&::before {
							content: "";

							width: .6rem;
							height: .6rem;


						}
					}
				}

				&--col03 {
					font-size: 1.8rem;
					font-weight: 500;
					line-height: 1.5;
				}
			}
		}

		&__contentArea {
			padding: 4rem 1rem 6rem;
			height: unset; // 梅田が追加

			&__Tit {
				font-size: 3.4rem;
				margin-bottom: 4rem;

				&__Aitest {
					font-size: 3.4rem;
					opacity: 95%; // 梅田が追加

					img {
						display: inline-block;
						width: 4rem;
						margin: 0 .5rem;
					}
				}
			}

			&.bg {
				&sec01 {
					&--pc {
						display: block;
						position: absolute;
						left: 50%;
						top: 0;
						transform: translateX(-50%);
						z-index: -1;
					}

					&--sp {
						display: none;

					}
				}

			}

			&.inner-space {
				margin-top: 7rem;
			}

			&__pickupName {
				margin: 13.5rem auto 2rem;
				font-size: 2.8rem;
			}

			&.contact {
				&::after {
					background-size: cover;
				}
			}
		}
	}
}