/* topics
=================================================================== */
.p-slider {
	&__feature {
		width: calc(100% + 2rem);
		margin-left: -1rem;

		&Item {
			border-radius: 12px;
			width: 100%;
			height: 24rem;
			position: relative;
			overflow: hidden;
			margin: 1rem .5rem;
			background: #000;
		}

		&Tit {
			width: 100%;
			text-align: center;
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: 1rem;
		}

		&Txt {
			color: #fff;
			text-shadow: 1px 1px 5px #000;
			position: absolute;
			z-index: 10;
			font-size: .9rem;
			width: 80%;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
		}

		&Img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
			opacity: .8;
		}
	}
}



@include mq-u(md) {
	.p-slider {
		&__feature {
			width: 100%;
			margin-left: 0;

			&Item {
				width: 100%;
				position: relative;
				margin: 1rem 1.5rem;
			}

			&Tit {
				font-size: 1.4rem;
			}

			&Txt {
				width: 42%
			}

			&Img {}
		}
	}
}