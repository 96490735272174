.l-footer {
	display: block;
	height: 10.5rem;
	padding: 0 0 .5rem;
	position: relative;
	color: #fff;

	&::after {
		content: '';
		display: block;
		position: absolute;
		background: #1D344E;
		width: 100%;
		height: 5rem;
		bottom: 0;
		z-index: -1;
	}

	&__primecast {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem 0;
		height: 5.5rem;
		background: linear-gradient(rgba(69, 171, 243, .9), rgba(2, 109, 231, .9));

	}

	&__logo {
		&--fig {
			display: block;
			width: 5rem;
			margin: 1.2rem auto 0;
		}

		&--primecast {
			width: 6rem;
			margin: auto;
		}
	}

	&__copyright {
		font-size: .8rem;
	}

	// 以下は梅田が追加
	.c-btn__pageTop {
		background: rgba(233, 68, 65, .8);
		border-radius: 100%;
		height: 3.6rem;
		width: 3.6rem;
		justify-content: center;
		position: fixed;
		right: 2%;
		bottom: 11.5%;
		z-index: 70;
		transition: transform 5s;
		animation: slideIn 1s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;

		&:hover {
			box-shadow: 0 0 3px #0c0d11;
			color: #fff;
			transform: scale(.9, .9);
		}

		& svg {
			fill: #fff;
		}

		@keyframes slideIn {
			0% {
				transform: translateY(180px);
				opacity: 0;
			}

			100% {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}

	// ここまでは梅田が追加
}


@include mq-u(md) {
	.l-footer {
		height: 18rem;

		&::after {
			height: 9.5rem;
		}

		&__primecast {
			height: 8.5rem;
		}

		&__logo {
			&--fig {
				display: block;
				width: 6rem;
				margin: .5rem auto .25rem;
			}

			&--primecast {
				width: 8rem;
				margin: auto;
			}
		}

		// 以下は梅田が追加
		.c-btn__pageTop {
			background: rgba(233, 68, 65, .8);
			border-radius: 100%;
			height: 5rem;
			width: 5rem;
			justify-content: center;
			position: fixed;
			right: 2%;
			bottom: 11.5%;
			z-index: 70;
			transition: transform 5s;
			animation: slideIn 1s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;

			&:hover {
				box-shadow: 0 0 3px #0c0d11;
				color: #fff;
				transform: scale(.9, .9);
			}

			& svg {
				fill: #fff;
			}

			@keyframes slideIn {
				0% {
					transform: translateY(180px);
					opacity: 0;
				}

				100% {
					transform: translateY(0);
					opacity: 1;
				}
			}
		}

		// ここまでは梅田が追加
	}
}